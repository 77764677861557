<template>
  <div class="container">
    <busy :visible="loading">Loading...</busy>
    <div v-if="!loading">
      <div class="card border-0 shadow">
        <div class="card-body">
          <h5>{{announcement.title}}</h5>
          <p class="my-0 pb-0">{{announcement.created_at | formatDate}}</p>
          <hr />

          <div v-html="announcement.body"></div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true,
      announcement: null
    };
  },
  methods: {
    fetchAnnouncement() {
      this.$axios
        .get(
          process.env.VUE_APP_API_URL +
            "/announcements/" +
            this.$route.params.id
        )
        .then(({ data }) => {
          this.announcement = data;
          this.loading = false;
        });
    },
  },
  created() {
    this.fetchAnnouncement();
  },
  filters: {
    formatDate(date) {
      return moment(date).format("LL");
    },
  }
};
</script>

<style>
.ql-align-left{
  text-align: left;
}
.ql-align-center{
  text-align: center;
}
.ql-align-right{
  text-align: right;
}
</style>
